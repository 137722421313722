import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/uoms', data);
  },
  get(id) {
    return api.fetch('/uoms/' + id);
  },
  list(params) {
    return api.fetch('/uoms', { params });
  },
  update(data) {
    return api.patch('/uoms', data);
  },
  delete(id) {
    return api.remove('/uoms', id);
  },
};
