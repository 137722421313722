<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1 class="mb-0">Relocation Item Master Manage</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <validation-observer ref="formRef" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col>
                      <h2>Customer Information</h2>
                    </b-col>

                    <b-col class="text-right">
                      <CompanySearch @on-select="(v) => onSelectCompany(v)" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Company Name"
                        label-for="v-ticket-no"
                      >
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-ticket-no"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Location" label-for="v-location">
                        <b-form-input
                          disabled
                          v-model="formData.location"
                          id="v-location"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Item</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Code" label-for="v-code">
                        <b-form-input
                          v-model="formData.item_code"
                          id="v-code"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Name" label-for="v-name">
                        <b-form-input
                          v-model="formData.item_name"
                          id="v-name"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Category" label-for="v-category">
                        <v-select
                          id="v-category"
                          label="name"
                          :clearable="false"
                          v-model="formData.category"
                          :options="categories"
                          :reduce="(categories) => categories.name"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label="Serial No." label-for="v-serial_no">
                        <b-form-input
                          v-model="formData.serial_no"
                          id="v-serial_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Barcode" label-for="v-barcode">
                        <b-form-input
                          v-model="formData.barcode"
                          id="v-barcode"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Quantity" label-for="v-quantity">
                        <b-form-input
                          type="number"
                          v-model.number="formData.quantity"
                          id="v-quantity"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="UOM" label-for="v-uom">
                        <b-form-select
                          id="v-uom"
                          v-model="formData.uom"
                          :options="uoms"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Description"
                        label-for="v-description"
                      >
                        <b-form-textarea
                          v-model="formData.description"
                          id="v-description"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Other</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Movement Refnum"
                        label-for="v-job_refnum"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.job_refnum"
                          id="v-job_refnum"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Movement Status"
                        label-for="v-movement_status"
                      >
                        <b-form-select
                          id="v-movement_status"
                          v-model="formData.movement_status"
                          :options="movements"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Bin No." label-for="v-bin_no">
                        <b-form-input v-model="formData.bin_no" id="v-bin_no" />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Condition" label-for="v-conditions">
                        <b-form-select
                          id="v-conditions"
                          v-model="formData.condition_status"
                          :options="conditions"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Remarks" label-for="v-remarks">
                        <b-form-textarea
                          v-model="formData.remarks"
                          id="v-remarks"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Upload File">
                        <b-form-file
                          v-model="fileUpload"
                          placeholder="Choose a file..."
                          id="fileUpload"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="!formData.base64">
                    <b-col>
                      <b-form-group>
                        FILE:
                        <span
                          class="text-primary cursor-pointer"
                          @click="onViewThumbnail"
                        >
                          {{ formData.filename }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="formData.base64">
                    <b-col class="text-center">
                      <b-form-group>
                        <div class="">
                          <b-img
                            v-if="fileType === 'image'"
                            height="250"
                            width="250"
                            thumbnail
                            fluid
                            :src="onViewThumbnail()"
                          />
                          <b-img
                            v-if="fileType === 'application'"
                            height="150"
                            width="150"
                            thumbnail
                            fluid
                            :src="require('@/assets/document.png')"
                          />
                          <video
                            height="250"
                            width="250"
                            controls
                            v-if="fileType === 'video'"
                          >
                            <source :src="onViewThumbnail()" />
                          </video>
                        </div>
                        <span>
                          {{ formData.filename }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        @click="goBack()"
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

              <span v-if="formData.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="relocation-item-masters"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="relocation-item-masters"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <!-- <b-tab title="Comments"> </b-tab>
                <b-tab title="Logs"> </b-tab> -->
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import statusesApi from '@api/statuses';
import uomsApi from '@api/uoms';
import relocationItemMasterApi from '@api/relocation_item_masters';

export default {
  name: 'RelocationItemMastersManage',
  mixins: [],
  components: {},
  data: () => ({
    activeTab: 0,
    formData: {
      id: 0,
      company_id: 0,
      company_name: null,
      location: null,
      item_code: null,
      item_name: null,
      description: null,
      serial_no: null,
      barcode: null,
      category: null,
      quantity: 0,
      uom: null,
      job_refnum: null,
      movement_status: null,
      bin_no: null,
      condition_status: null,
      remarks: null,

      filename: null,
      base64: null,
    },
    fileUpload: null,
    fileType: null,
    categories: [],
    conditions: [],
    movements: [],
    uoms: [],
  }),
  mounted() {
    this.loadUOMS();
    this.loadSystemCode();

    if (this.$route.query.id) {
      this.loadRelocationItemMaster();
    }
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.notesRef.loadNotes();
      } else if (v === 2) {
        this.$refs.documentsRef.loadDocuments();
      }
    },
    fileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const { name, type } = file;
          this.formData.filename = name;
          this.formData.base64 = reader.result;
          this.fileType = type.split('/')[0];
        };
      }
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.$route.query.id && !this.$route.query.duplicate
          ? 'Update'
          : 'Submit';
      },
    },
  },
  methods: {
    loadRelocationItemMaster() {
      relocationItemMasterApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: 'code=movement:status|condition:status',
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const [ms] = data.filter((x) => {
              return x.code === 'movement:status';
            });
            if (ms) {
              this.movements = ms.selections;
            }

            const [cs] = data.filter((x) => {
              return x.code === 'condition:status';
            });
            if (cs) {
              this.conditions = cs.selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUOMS() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
      };

      uomsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data) {
            for (const item of data) {
              this.uoms.push({ text: item.name, value: item.name });
            }

            console.log(this.uoms);
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      this.$refs.formRef.validate().then((success) => {
        if (success) {
          this.formData.file = this.formData.filename;

          let app;
          if (this.formData.id && !this.$route.query.duplicate) {
            app = relocationItemMasterApi.update(this.formData);
          } else {
            app = relocationItemMasterApi.add(this.formData);
          }

          if (app) {
            app
              .then(({ data }) => {
                this.toastConfig();
                const url = `/pages/masterlists/relocation-item-masters/view?id=${data.id}`;
                this.$router.push(url);
              })
              .catch(() => {
                this.toastConfig('', 'error');
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    onSelectCompany(event) {
      console.log(event);
      if (event) {
        const { id, company_name, addr1, addr2, country, postal } = event;

        this.formData.company_id = parseInt(id);
        this.formData.company_name = company_name;

        let location = addr1;

        if (addr2 && addr2 !== 'NIL') {
          location += ` ${addr2}`;
        }

        if (country && country !== 'NIL') {
          location += ` ${country}`;
        }

        if (postal && postal !== 'NIL') {
          location += ` ${postal}`;
        }

        this.formData.location = location;

        console.log(location);
      }
    },
    goBack() {
      this.$router.push('/pages/masterlists/relocation-item-masters');
    },
    onViewThumbnail() {
      if (this.formData.base64) {
        return this.formData.base64;
      } else {
        const params = {
          model: 'relocation-item-masters',
          id: this.formData.id,
          thumbnail: this.formData.file,
        };

        return this.getImage(params);
      }
    },
  },
};
</script>

<style lang="scss"></style>
